.container {
  @apply
    basis-[200px] pl-[40px]
    flex items-center gap-[5px]
    text-[14px] leading-[12px]
}

.dot {
  @apply
    text-[16px]
}

.red {
  @apply
    text-red
}

.yellow {
  @apply
    text-yellow
}

.green {
  @apply
    text-green
}
.lightGreen {
  color: #96bd5c
}

.orange {
  color: orange
}

.lightRed {
  color: #ff4500
}