@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply relative m-0 p-0 font-["Roboto",sans-serif] bg-[#fafafa] text-[16px]
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}
