.label {
  @apply
    cursor-pointer h-[16px] max-w-max
    font-medium text-gray-96 text-[14px]
    flex items-center
    hover:opacity-75 duration-100
}

.realRadio {
  @apply
    w-0 h-0 absolute opacity-0 -z-10
}

.customRadio {
  @apply
    w-[16px] h-[16px] relative mr-[8px]
    rounded-circle border-[1px] border-gray-96
    flex justify-center items-center
}

.roundCheck {
  @apply
    absolute w-[10px] h-[10px] rounded-circle
    scale-0 duration-150
}

.realRadio:checked + .customRadio .roundCheck {
  @apply
    scale-100
}

.red {
  @apply
    text-red
}

.yellow {
  @apply
    text-yellow
}

.green {
  @apply
    text-green
}
.lightGreen {
  color:#96bd5c
}
.orange {
  color:orange

}
.lightRed {
  color:#ff4500
}
